(function() {

Object.assign(dmx.rules, {

    accept: {
        message: 'This file type is not allowed for upload.',
        validity: function(element, param) {
            if (element.type.toLowerCase() != 'file') {
                return true;
            }

            var allowed = param.replace(/\s/g, '').split(',');

            if (element.files && element.files.length) {
                for (var i = 0; i < element.files.length; i++) {
                    var file = element.files[i], ok = false;

                    for (var j = 0; j < allowed.length; j++) {
                        var check = allowed[j];

                        if (check.charAt(0) == '.') {
                            if (file.name.match(new RegExp('\\' + check + '$', 'i'))) {
                                ok = true;
                                break;
                            }
                        } else if (/(audio|video|image)\/\*/i.test(check)) {
                            if (file.type.match(new RegExp('^' + check.replace(/\*/g, '.*') + '$', 'i'))) {
                                ok = true;
                                break;
                            }
                        } else {
                            if (file.type.toLowerCase() == check.toLowerCase()) {
                                ok = true;
                                break;
                            }
                        }
                    }

                    if (!ok) {
                        return false;
                    }
                }
            }

            return true;
        }
    },

    minsize: {
        message: 'Please select a file of at least {0} bytes.',
        validity: function(element, param) {
            if (element.type.toLowerCase() != 'file') {
                return true;
            }

            if (element.files && element.files.length) {
                for (var i = 0; i < element.files.length; i++) {
                    if (element.files[i].size < param) return false;
                }
            }

            return true;
        }
    },

    maxsize: {
        message: 'Please select a file of no more than {0} bytes.',
        validity: function(element, param) {
            if (element.type.toLowerCase() != 'file') {
                return true;
            }

            if (element.files && element.files.length) {
                for (var i = 0; i < element.files.length; i++) {
                    if (element.files[i].size > param) return false;
                }
            }

             return true;
        }
    },

    mintotalsize: {
        message: 'Total size of selected files should be at least {0} bytes.',
        validity: function(element, param) {
            if (element.type.toLowerCase() != 'file') {
                return true;
            }

            if (element.files && element.files.length) {
                var size = 0;
                for (var i = 0; i < element.files.length; i++) {
                    size += element.files[i].size;
                }
                if (size < param) return false;
            }

             return true;
        }
    },

    maxtotalsize: {
        message: 'Total size of selected files should be no more than {0} bytes.',
        validity: function(element, param) {
            if (element.type.toLowerCase() != 'file') {
                return true;
            }

            if (element.files && element.files.length) {
                var size = 0;
                for (var i = 0; i < element.files.length; i++) {
                    size += element.files[i].size;
                }
                if (size > param) return false;
            }

             return true;
        }
    },

    minfiles: {
        message: 'Please select at least {0} files.',
        validity: function(element, param) {
            if (element.type.toLowerCase() != 'file') {
                return true;
            }

            if (element.files && element.files.length) {
                if (element.files.length < param) return false;
            }

            return true;
        }
    },

    maxfiles: {
        message: 'Please select no more than {0} files.',
        validity: function(element, param) {
            if (element.type.toLowerCase() != 'file') {
                return true;
            }

            if (element.files && element.files.length) {
                if (element.files.length > param) return false;
            }

            return true;
        }
    }

});

})();
